<ng-container *ngIf="(isMobile$ | async) && group">
    <div class="side-nav" [ngStyle]="{
            background: background,
            color: color
        }">
        @if(stickyHeader) {
            <div class="side-nav__header" [ngStyle]="{ background: background }">
                <div class="side-nav__header-wrapper">
                    <a [routerLink]="['/']">
                        <esc-icon class="side-nav__header-icon" icon="earlystageLogo" iconType="svg" fill />
                    </a>
                    <span class="side-nav__header-group">{{ group.fullName }}</span>
                </div>

                <button type="button" class="side-nav__toggler" (click)="offExpand()" [ngStyle]="{
                    background: background,
                    'box-shadow': stickyHeader ? 'none' : null
                }">
                    <esc-icon class="side-nav__toggler-icon" icon="close" fill />
                </button>
            </div>
        }

        <div #container class="side-nav__content" data-scroll-lock-scrollable>
            <div class="side-nav__navigation">
                <es-class-header-navigation />
            </div>

            <section class="side-nav__schedule" *ngIf="group$ | async as group">
                <span class="side-nav__schedule-title">ZAJĘCIA GRUPY:</span>
                <es-class-header-schedule-item [group]="group" />
            </section>

            <div class="side-nav__divider"></div>

            <section class="side-nav__select" *ngIf="group$ | async as group">
                <span class="side-nav__select-title">GRUPA:</span>
                <es-class-header-group-select [groups]="groups$" [group]="group" />
            </section>

            <div class="side-nav__divider"></div>

            <section class="side-nav__info" *ngIf="group$ | async as group">
                <span class="side-nav__info-title">INFORMACJE O GRUPIE:</span>
                <es-class-header-group-info [group]="group" />
            </section>

            <div class="side-nav__divider"></div>

            <section class="side-nav__controls" *ngIf="group$ | async as group">
                <es-class-header-controls />
            </section>

            <section class="side-nav__actions">
                <a [routerLink]="exitUrl || ['/']" class="side-nav__exit-button">Wyjdź z dziennika grupy</a>
            </section>
        </div>
    </div>
</ng-container>