<div class="class-header-schedule-wrapper">
    <div class="class-header-schedule-item">
        <div class="class-header-schedule-item__left">
            @if(group().isFirstDayOnline) {
            <esc-icon icon="videocam" fill large />
            } @else {
            <esc-icon icon="domain" fill large />
            }
        </div>
        <div class="class-header-schedule-item__right">
            <p class="class-header-schedule-item__title">
                {{ getSchedule(group().firstDayOfWeek, group().firstDayStartHour, group().firstDayFinishHour) }}
            </p>
            @if(group().isFirstDayOnline) {
            <p class="class-header-schedule-item__text">Zajęcia online</p>
            @if(group().zoomUrl) {
            <a class="class-header-schedule-item__zoom" href="{{ group().zoomUrl }}" target="_blank">
                Dołącz do zajęć <esc-icon icon="open_in_new" small />
            </a>
            }
            }
            @else {
            <div class="class-header-schedule-item__description">
                <p class="class-header-schedule-item__text">{{ group().firstDayLocalization?.name }}</p>
                <p class="class-header-schedule-item__text">{{ group().firstDayLocalization?.address }}</p>
                @if(group().firstDayRoom) {
                <p class="class-header-schedule-item__text">
                    Sala: <span>{{ group().firstDayRoom?.name }}</span>
                </p>
                }
            </div>
            }
        </div>
    </div>

    @if(group().level?.lessonsPerWeek === 2 && group().secondDayOfWeek) {
    <div class="class-header-schedule-item">
        <div class="class-header-schedule-item__left">
            @if(group().isSecondDayOnline) {
            <esc-icon icon="videocam" fill large />
            } @else {
            <esc-icon icon="domain" fill large />
            }
        </div>
        <div class="class-header-schedule-item__right">
            <p class="class-header-schedule-item__title">
                {{ getSchedule(group().secondDayOfWeek, group().secondDayStartHour, group().secondDayFinishHour) }}
            </p>
            @if(group().isSecondDayOnline) {
            <p class="class-header-schedule-item__text">Zajęcia online</p>
            @if(group().zoomUrl) {
            <a class="class-header-schedule-item__zoom" href="{{ group().zoomUrl }}" target="_blank">
                Dołącz do zajęć <esc-icon icon="open_in_new" small />
            </a>
            }
            }
            @else {
            <div class="class-header-schedule-item__description">
                <p class="class-header-schedule-item__text">{{ group().secondDayLocalization?.name }}</p>
                <p class="class-header-schedule-item__text">{{ group().secondDayLocalization?.address }}</p>
                @if(group().secondDayRoom) {
                <p class="class-header-schedule-item__text">
                    Sala: <span>{{ group().secondDayRoom?.name }}</span>
                </p>
                }
            </div>
            }
        </div>
    </div>
    }
</div>