import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalHeaderDirective } from 'apps/early-stage-office/src/app/utility-modules/modals/directives/modal-header.directive';
import { ModalContentDirective } from 'apps/early-stage-office/src/app/utility-modules/modals/directives/modal-content.directive';
import { ModalConfig } from 'apps/early-stage-office/src/app/utility-modules/modals/services/modal-config';
import { ISimplifiedGroup } from '../../../core/models/group.interface';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'es-group-select-modal',
    standalone: true,
    imports: [CommonModule, ModalHeaderDirective, ModalContentDirective, RouterLink],
    templateUrl: './group-select-modal.component.html',
    styleUrl: './group-select-modal.component.scss',
})
export class GroupSelectModalComponent {
    public groups: ISimplifiedGroup[] = []
    public selectedGroupId: number;

    constructor(
        public modalConfig: ModalConfig<{
            groups,
            selectedGroupid
        }>,
    ) {
    }

    ngOnInit() {
        this.groups = this.modalConfig.data.groups;
        this.selectedGroupId = this.modalConfig.data.selectedGroupid;
    }
}
