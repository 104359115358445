import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { getPolishDayOfWeek, IconComponent } from '@early-stage/components';
import { IGroup } from 'apps/early-stage-office/src/app/core/models/group.interface';

@Component({
    selector: 'es-class-header-schedule-item',
    standalone: true,
    imports: [CommonModule, IconComponent],
    templateUrl: './class-header-schedule-item.component.html',
    styleUrl: './class-header-schedule-item.component.scss',
})
export class ClassHeaderScheduleItemComponent {
    group = input<IGroup>();


    public getDayOfWeek(day: number): string {

        return getPolishDayOfWeek(day);
    }

    public getSchedule(dayOfWeek: number | undefined, startHour: string | undefined, finishHour: string | undefined): string {

        if (!dayOfWeek || !startHour || !finishHour) {
            return '';
        }

        const day = this.getDayOfWeek(dayOfWeek);

        return `${day} ${startHour} - ${finishHour}`;
    }
}
