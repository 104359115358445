<div class="class-header-group-info">
    <div class="class-header-group-info__item">
        <span class="class-header-group-info__title">
            Grupa:
        </span>
        <span class="class-header-group-info__description">
            {{ group().realization?.names?.simple }} {{ group().name }}
        </span>
    </div>
    <div class="class-header-group-info__item">
        <span class="class-header-group-info__title">
            Filia:
        </span>
        <span class="class-header-group-info__description">
            {{ group()?.agency?.name }}
        </span>
    </div>
    <div class="class-header-group-info__item">
        <span class="class-header-group-info__title">
            Lektor:
        </span>
        <span class="class-header-group-info__description">
            {{ group().lector?.lastName }} {{ group().lector?.firstName }}
        </span>
    </div>
    @if(group().substituteLector) {
    <div class="class-header-group-info__item">
        <span class="class-header-group-info__title">
            Zastępstwo:
        </span>
        <span class="class-header-group-info__description">
            {{ group().substituteLector?.lastName }} {{ group().substituteLector?.firstName }}
        </span>
    </div>
    }
</div>