import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@early-stage/components';
import { Router } from '@angular/router';
import { ModalsService } from 'apps/early-stage-office/src/app/utility-modules/modals/services/modals.service';
import { SupportModalComponent } from 'apps/early-stage-office/src/app/shared/modals/support-modal/support-modal.component';
import { Select, Store } from '@ngxs/store';
import { ColorPickerModalComponent } from 'apps/early-stage-office/src/app/shared/modals/color-picker-modal/color-picker-modal.component';
import { GetClassRegisterGroup } from 'apps/early-stage-office/src/app/modules/class-register/store/class-register.actions';
import { GetProfile } from 'apps/early-stage-office/src/app/core/store/profile/profile.actions';
import { IGroup } from 'apps/early-stage-office/src/app/core/models/group.interface';
import { ClassRegisterState } from 'apps/early-stage-office/src/app/modules/class-register/store/class-register.state';
import { Observable } from 'rxjs';
import { IProfile } from 'apps/early-stage-office/src/app/core/models/profile.interface';
import { ProfileState } from 'apps/early-stage-office/src/app/core/store/profile/profile.state';

@Component({
    selector: 'es-class-header-controls',
    standalone: true,
    imports: [CommonModule, IconComponent],
    templateUrl: './class-header-controls.component.html',
    styleUrl: './class-header-controls.component.scss',
})
export class ClassHeaderControlsComponent {
    public isMainGroupLector: boolean;
    private _profile: IProfile;

    @Select(ClassRegisterState.group) public group$: Observable<IGroup>;

    constructor(
        public store: Store,
        public router: Router,
        private _modals: ModalsService,
    ){}


    public ngOnInit(): void {
        this._profile = this.store.selectSnapshot(ProfileState.profile);

        this.group$.subscribe(group => {
            this.isMainGroupLector = group?.lector.email === this._profile.user.email;
        });
    }


    public openSupportModal(): void {
        setTimeout(() => {
            this.router.navigate([{ outlets: { menu: null } }]);

            this._modals.open(SupportModalComponent, {
                style: 'anchored',
                anchorType: 'right',
                width: 500,
            });
        });
    }

    public async editGroupColor(group: IGroup): Promise<void> {
        await this._modals
            .open(ColorPickerModalComponent, {
                width: 378,
                data: {
                    group,
                },
            })
            .result();

        this.store.dispatch(new GetClassRegisterGroup({ id: group.id }));
        this.store.dispatch(new GetProfile());
    }
}
