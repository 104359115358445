import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from "../../../../navigation/navigation.component";

@Component({
    selector: 'es-class-header-navigation',
    standalone: true,
    imports: [CommonModule, NavigationComponent],
    templateUrl: './class-header-navigation.component.html',
    styleUrl: './class-header-navigation.component.scss',
})
export class ClassHeaderNavigationComponent {}
