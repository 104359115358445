import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@early-stage/components';
import { IGroup, ISimplifiedGroup } from 'apps/early-stage-office/src/app/core/models/group.interface';
import { ModalsService } from 'apps/early-stage-office/src/app/utility-modules/modals/services/modals.service';
import { GroupSelectModalComponent } from 'apps/early-stage-office/src/app/shared/modals/group-select-modal/group-select-modal.component';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'es-class-header-group-select',
    standalone: true,
    imports: [CommonModule, IconComponent],
    templateUrl: './class-header-group-select.component.html',
    styleUrl: './class-header-group-select.component.scss',
})
export class ClassHeaderGroupSelectComponent {
    groups = input<Observable<ISimplifiedGroup[]>>();
    group = input<IGroup>();

    private destroy$ = new Subject<boolean>();
    public parsedGroups: ISimplifiedGroup[] = []; 

    constructor(
        private _modals: ModalsService,
    ){}

    ngOnInit() {
        this.groups().pipe(takeUntil(this.destroy$)).subscribe((groupData: ISimplifiedGroup[]) => {
            this.parsedGroups = groupData;
        });
    }

    public async openGroupSelectModal(): Promise<void> {
        await this._modals
            .open(GroupSelectModalComponent, {
                width: 378,
                data: {
                    groups: this.parsedGroups,
                    selectedGroupid: this.group().id
                },
            })
            .result();

        // this.store.dispatch(new GetClassRegisterGroup({ id: group.id }));
        // this.store.dispatch(new GetProfile());
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
