import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IGroup } from 'apps/early-stage-office/src/app/core/models/group.interface';

@Component({
    selector: 'es-class-header-group-info',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './class-header-group-info.component.html',
    styleUrl: './class-header-group-info.component.scss',
})
export class ClassHeaderGroupInfoComponent {
    group = input<IGroup>();
}
