import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
    production: true,
    apiUrl: 'https://test-api.earlystage.pl/api/',
    name: 'test',
    test: true,
    ESOnlineUrl: 'https://test-online.earlystage.pl/',
    ESOnlineSecret: 'hs#GV9eC7=s2KA=N',
    GTMTrackingId: 'GTM-KCMZXQZ',
    sentry: true,
};
