<div class="group-select-modal">
    <div esModalHeader>
        <div class="modal__wrapper">
            <h2 class="group-select-modal__title">Twoje grupy</h2>
        </div>
    </div>

    <div esModalContent>
        @if(groups.length && groups.length > 0) {
            <ul class="group-select-modal__list">
                @for (group of groups; track group.id) {
                    <li class="group-select-modal__list-item">
                        <a [routerLink]="['', { outlets: { menu: null, primary: 'edziennik/' + group.id } }]"
                            class="group-select-modal__button" [ngClass]="{ 'is-active': group.id === selectedGroupId }">
                            <span class="group-select-modal__indicator"
                                [style.background-color]="group.color.background || '#f2f2f2'"></span>
                            <span class="group-select-modal__group-name">{{ group.fullName }}</span>
                        </a>
                    </li>
                }
            </ul>
        } @else {
            <div>Tu zobaczysz grupy z dziennikiem, jak tylko zostaną Ci przypisane</div>
        }
    </div>
</div>