<div class="class-header-controls">
    @if(isMainGroupLector) {
        <button *ngIf="group$ | async as group" class="class-header-controls__button" (click)="editGroupColor(group)">
            <esc-icon icon="brush" fill/>
            <span class="class-header-controls__title">Zmień kolor</span>
        </button>
    }
    <button class="class-header-controls__button" (click)="openSupportModal()">
        <esc-icon icon="help"/>
        <span class="class-header-controls__title">Pomoc</span>
    </button>
</div>