import { enableProdMode, Injectable, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { environment } from './environments/environment';
import { enableDebugTools, Title, BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { APP_ROUTES } from './app/app-routes';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { UrlSerializer, DefaultUrlSerializer, UrlTree, RouterModule, provideRouter, RouteReuseStrategy, withRouterConfig } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { AuthState } from './app/core/store/auth/auth.state';
import { ProfileState } from './app/core/store/profile/profile.state';
import { LayoutState } from './app/core/store/layout/layout.state';
import { DictionaryState } from './app/core/store/dictionary/dictionary.state';
import { LessonsScheduleState } from './app/core/store/misc/lessons-schedule.state';
import { NgxsStoragePluginModule, STORAGE_ENGINE } from '@ngxs/storage-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptor } from './app/core/interceptors/auth.interceptor';
import { OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { CustomStorageEngine } from './app/core/custom-storage-engine';
import { CustomReuseStrategy } from './app/core/services/route-reuse-strategy';
import { JsonInterceptor } from './app/core/interceptors/json.interceptor';
import localePl from '@angular/common/locales/pl';
import { registerLocaleData } from '@angular/common';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { SchoolYearSelectorState } from './app/core/store/school-year-selector/school-year-selector.state';

registerLocaleData(localePl);

export const DATE_FORMATS = {
    parseInput: 'l LT',
    fullPickerInput: 'l LT',
    datePickerInput: 'L',
    timePickerInput: 'LT',
    monthYearLabel: 'LLLL YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'LLLL YYYY',
};

@Injectable()
export class MyUrlSerializer extends DefaultUrlSerializer implements UrlSerializer {
    /** Converts a `UrlTree` into a url */
    public serialize(tree: UrlTree): string {
        return super.serialize(tree);
    }
}

// Sentry.init({
//     dsn: 'https://6814cea0a24b4482a186a52c145aead1@sentry.io/1767829',
//     environment: environment.name,
//     ignoreErrors: [
//         // /Loading chunk [\d]+ failed/gm,
//         // /Unexpected token '<'/gm,
//         // /SyntaxError: expected expression, got '<'/gm,
//         /400 OK/gm,
//     ],
//
//
//     release: 'earlystage@1.0.0',
//     integrations: [
//         // Registers and configures the Tracing integration,
//         // which automatically instruments your application to monitor its
//         // performance, including custom Angular routing instrumentation
//
//         /*new BrowserTracing({
//           tracingOrigins: ['localhost', 'https://office.earlystage.pl', 'https://test-api.earlystage.pl/api/', 'https://office-api.earlystage.pl/api/'],
//           routingInstrumentation: Sentry.routingInstrumentation,
//         }),*/
//     ],
//
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//
//     tracesSampleRate: 0.1,
//
//     autoSessionTracking: false,
// });

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        provideRouter(APP_ROUTES, withRouterConfig({ paramsInheritanceStrategy: 'always' })),
        provideHttpClient(withInterceptorsFromDi()),
        importProvidersFrom(
            BrowserModule,
            ServiceWorkerModule.register('ngsw-worker.js', {
                enabled: environment.production,
                // Register the ServiceWorker as soon as the app is stable
                // or after 30 seconds (whichever comes first).
                registrationStrategy: 'registerWhenStable:5000',
            }),

            RouterModule,
            BrowserAnimationsModule,

            NgxsModule.forRoot([AuthState, ProfileState, LayoutState, DictionaryState, LessonsScheduleState, SchoolYearSelectorState]),
            NgxsStoragePluginModule.forRoot({
                keys: [
                    'auth.token',
                    'layout.returnUrl',
                    'profile.currentRole',
                    'franchisePage.franchiseGroups.currentGroupsImportResult',
                    'franchisePage.franchiseGroups.currentGroupsImportDTO',
                    'franchisePage.franchiseGroups.currentGroupsImportCounters',
                    'franchisePage.selectedStudentListTableSchema',
                    'franchisePage.selectedSchoolYear',
                    // 'layout.tableSnapshots',
                    'layout.tabsSnapshots',
                    'layout.tabsSnapshots',
                    'schoolYearSelector.selectedYear',
                ],
            }),

            NgxsRouterPluginModule.forRoot(),
            NgxsReduxDevtoolsPluginModule.forRoot(),
            OwlDateTimeModule,
            OwlNativeDateTimeModule,
            InlineSVGModule.forRoot(),
            GoogleTagManagerModule.forRoot({
                id: environment.GTMTrackingId,
            })
        ),

        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JsonInterceptor,
            multi: true,
        },
        {
            provide: 'ENV',
            useValue: environment,
        },
        { provide: UrlSerializer, useClass: MyUrlSerializer },
        { provide: OWL_DATE_TIME_LOCALE, useValue: 'pl' },
        { provide: OWL_DATE_TIME_FORMATS, useValue: DATE_FORMATS },
        { provide: LOCALE_ID, useValue: 'pl-PL' },
        {
            provide: STORAGE_ENGINE,
            useClass: CustomStorageEngine,
        },
        { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },

        /*{
            provide: Sentry.TraceService,
            deps: [Router],
        },*/
        Title,
        provideAnimations(),
    ],
})
    .then((appRef: any) => {
        enableDebugTools(appRef);

        if ('serviceWorker' in navigator && environment.production) {
            navigator.serviceWorker.register('ngsw-worker.js');
        }
    })
    .catch(err => console.error(err));
